<template>
    <el-card shadow="never">
        <template #header>
            <div class="clearfix" style="text-align:left">
                <span>优惠券列表</span>
            </div>
        </template>
        <div class="content">
            <div class="content-header">
                <el-row :gutter="5">
                    <el-col :span="24"   style="text-align:left;">
                        <div class="searchblock">
                            <el-input v-model="keyword" placeholder="优惠券名称" style="width:200px;margin-right:10px;"></el-input>
                            <el-select v-model="CouponType" placeholder="请选择优惠券类型" style="width:200px;margin-right:10px;" clearable>
                                <el-option label="首期款租金券" value="1"></el-option>
                                <el-option label="延长还机时间" value="2"></el-option>
                                <el-option label="首期一元优惠券" value="3"></el-option>
                                <el-option label="满减优惠券" value="4"></el-option>
                            </el-select>
                            <el-select v-model='Type' placeholder="领取方式" style="width:200px;margin-right:10px;" clearable>
                                <el-option label="指定送" value="1"></el-option>
                                <el-option label="领取送" value="2"></el-option>
                            </el-select>
                            <el-select v-model="Status" placeholder="请选择优惠券状态" style="width:200px;margin-right:10px;">
                                <el-option label="全部" value="0"></el-option>
                                <el-option label="未生效" value="1"></el-option>
                                <el-option label="生效中" value="2"></el-option>
                                <el-option label="已失效" value="3"></el-option>
                            </el-select>
                            <el-button @click="AddCoupon" type="primary">创建优惠券</el-button>
                            <el-button @click="init" type="success">搜索</el-button>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="content-main">
            <el-table :data="tabledata" border style="width: 100%; margin: 1em 0"
                                v-loading="load" 
                                element-loading-spinner="el-icon-loading" 
                                element-loading-text="加载中..."
                                size="medium"
                                >
                <el-table-column label="优惠券名称" prop="CouponName"></el-table-column>
                <el-table-column label="优惠券编码" prop="CouponCode"></el-table-column>
                <el-table-column label="领券方式">
                    <template #default='scope'>
                    <span v-if="scope.row.Type == 1">指定送</span>
                    <span v-if="scope.row.Type == 2">领取送</span>
                    </template>
                </el-table-column>
                <el-table-column label="优惠券类型">
                    <template #default='scope'>
                    <span v-if="scope.row.CouponType == 1">首期款租金券</span>
                    <span v-if="scope.row.CouponType == 2">延长还机时间</span>
                    <span v-if="scope.row.CouponType == 3">首期一元优惠券"</span>
                    <span v-if="scope.row.CouponType == 4">满减优惠券</span>
                    </template>
                </el-table-column>
                <el-table-column label="使用条件">
                    <template #default='scope'>
                    <span v-if="scope.row.CouponType == 1">
                        满{{scope.row.ConditionVal}}元可用
                    </span>
                    <span v-if="scope.row.CouponType == 2 ">
                       用户租满{{scope.row.ConditionVal}}天可用
                    </span>
                    <span v-if="scope.row.CouponType == 4">
                       用户租满{{scope.row.ConditionVal}}元可用
                    </span>
                    </template>
                </el-table-column>
                <el-table-column label="面额/折扣">
                    <template #default='scope'>
                    <span v-if="scope.row.CouponType == 1 || scope.row.CouponType == 4">{{scope.row.CouponValue}}元</span>
                    <span v-if="scope.row.CouponType == 2">延长{{scope.row.CouponValue}}天</span>
                    </template>
                </el-table-column>
                <el-table-column label="发放开始时间" >
                    <template #default='scope'>
                        <span v-if="scope.row.StartDTime != 0">{{ formatDate(scope.row.StartDTime) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="发放结束时间" >
                    <template #default='scope'>
                        <span v-if="scope.row.EndDTime != 0">{{ formatDate(scope.row.EndDTime) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="使用有效期开始" prop="AvaliableDTime">
                    <template #default='scope'>
                        <span v-if="scope.row.AvaliableDTime != 0">{{ formatDate(scope.row.AvaliableDTime) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="使用有效期结束" prop="ExpireDime">
                    <template #default='scope'>
                        <span v-if="scope.row.UseTimeType == 1">自领取起{{scope.row.UseTime}}天内有效</span>
                        <span v-if="scope.row.UseTimeType == 2 && scope.row.ExpireDTime != 0">
                            {{ formatDate(scope.row.ExpireDTime) }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="领取渠道">支付宝小程序</el-table-column>
                <el-table-column label="使用渠道">支付宝小程序</el-table-column>
                <el-table-column label="状态">
                    <template #default='scope'>
                        <div v-if="scope.row.Type == 2">
                            <span v-if="scope.row.Status == 1">{{scope.row.Statustxt}}</span>
                            <span v-if="scope.row.Status == 2">已失效</span>
                        </div>
                        <div v-else>
                            <span v-if="scope.row.Status == 1">有效</span>
                            <span v-if="scope.row.Status == 2">已失效</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template #default='scope'>
                    <div v-if="scope.row.Type == 1">
                        <el-button @click="EditCoupon(scope.row.ID)" type="text" v-if="scope.row.Status == 1">编辑</el-button>
                        <el-button v-if="scope.row.Status == 1" @click="Disable(scope.row.ID)" type="text">失效</el-button>
                        <el-button v-if="scope.row.Status == 2" @click="Del(scope.row.ID)" type="text">删除</el-button>
                    </div>
                    <div v-else>
                        <el-button @click="EditCoupon(scope.row.ID)" type="text" v-if="scope.row.Statustxt != '已失效' && scope.row.Status == 1">编辑</el-button>
                        <el-button v-if="scope.row.Statustxt == '生效中'" @click="Disable(scope.row.ID)" type="text">失效</el-button>
                        <el-button v-if="scope.row.Statustxt == '已失效' || scope.row.Status == 2" @click="Del(scope.row.ID)" type="text">删除</el-button>
                    </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </el-card>
</template>
<style scoped>

</style>
<script>
import constant from "@/constant"

export default {
    data(){
        return{
            Type:'',
            CouponType:'',
            Status:'',
            keyword:'',
            tabledata:[],
        }
    },
    methods:{
        EditCoupon(id){
            this.$router.push({path:'/addcoupon',query:{id:id}});
        },
        AddCoupon(){
            this.$router.push({path:'/addcoupon'});
        },
        Getdate(row, column){
            var cur_date = row[column.property];
            console.log(cur_date);
            return  formatDate(cur_date);
            function formatDate(date){
                var datetime = new Date(date*1000);
                var year = datetime.getFullYear();
                var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
                var day = ("0" + datetime.getDate()).slice(-2);
                var hour = ("0" + datetime.getHours()).slice(-2);
                var minute = ("0" + datetime.getMinutes()).slice(-2); 
                var second = ("0" + datetime.getSeconds()).slice(-2); 
                return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
            }
        },
        formatDate(date){
            if(date){
                var datetime = new Date(date*1000);
                var year = datetime.getFullYear();
                var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
                var day = ("0" + datetime.getDate()).slice(-2);
                var hour = ("0" + datetime.getHours()).slice(-2);
                var minute = ("0" + datetime.getMinutes()).slice(-2); 
                var second = ("0" + datetime.getSeconds()).slice(-2); 
                return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
            }
            else
                return '';
        },
        init(){
            if(this.Status && !this.Type){
                this.$message.error("请选择领券方式");
                return false;
            }
            this.axios.get(constant.get_coupon_list,{
                headers: {
                    "Content-Type": "application/json",
                },
                params:{
                    keyword: this.keyword,
                    status: this.Status,
                    coupontype: this.CouponType,
                    type: this.Type,
                }
            }).then((response)=>{
                console.log(response.data);
                this.tabledata = response.data;
            });
        },
        Disable(id){
            console.log(id);
            this.$confirm('确定要使该优惠券失效么?','提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(()=>{
                this.axios.get(constant.disable_coupon,{
                    headers:{
                        "Content-Type": "application/json",
                    },
                    params:{
                        couponid:id
                    }
                }).then((response)=>{
                    console.log(response.data);
                    if(response.data == 'OK'){
                        this.$message.success("操作成功");
                        this.$router.go(0);
                    }else
                        return false;
                })
            })
        },
        Del(id){
            this.$confirm('确定要删除该优惠券么?','提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(()=>{
                this.axios.get(constant.del_coupon,{
                    headers:{
                        "Content-Type": "application/json",
                    },
                    params:{
                        couponid:id
                    }
                }).then((response)=>{
                    console.log(response.data);
                    if(response.data == 'OK'){
                        this.$message.success("操作成功");
                        this.$router.go(0);
                    }else
                        return false;
                })
            })
        }
    },
    created:function(){
        this.axios.get(constant.get_coupon_list,{
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response)=>{
            console.log(response.data);
            this.tabledata = response.data;
        });
    }
}
</script>